<template>
  <main v-if="store.routesOther && Object.values(store.routesOther).length != 0">
    <p class="title-dest m-top-20-px container">Otros enlaces de interés</p>
    <span class="dot__line m-left-16"></span>
    <div class="grid-3">
      <a v-for="(route, key) in store.routesOther" :key="key" class="text-decoration-none font-fpamily-frank-ruhl text-light text-primary font-size-19 d-block m-bottom-10-px grid" :href="route.url" :title="'Ir a '+route.url" :target="[route.blank == 1 ? '_blank' : '_self']">{{route.name}}</a>
    </div>
  </main>
</template>

<script>
  import { contentStore } from '@/stores/contents'
  export default {
    name: "OtherLinksInterest",
    setup() {
      const store = contentStore();
      store.loadRoutes()
      return {
        store
      }
    },
  }
</script>