<template>
  <div>
    <hero-global title="Contacto" img="/img/contact/contact-1.svg" />
    <section class="menu-anclas container">
      <router-link to="#donde-estamos" @click="scrollSection('donde-estamos')" class="btn btn-tertiary">Dondé estamos</router-link>
      <router-link to="#como-llegar" @click="scrollSection('como-llegar')" class="btn btn-tertiary">Cómo llegar</router-link>
      <router-link to="#formulario" @click="scrollSection('formulario')" class="btn btn-tertiary">Formulario</router-link>
    </section>
    <section class="text-center position-relative m-top-40-px" id="donde-estamos">
      <h2 class="title-grand title__double-center" data-text="Dónde estamos">Dónde estamos</h2>
    </section>

    <section class="grid-3 justify-content-center m-top-40-px m-bottom-40-px container">
      <article class="grid m-1-px">
        <h2 class="subtitle font-size-21">CERMI (Comité Español de Representantes de Personas con Discapacidad)</h2>
        <ul class="list-style-none font-family-roboto font-light font-size-16">
          <li class="p-10-px">
            <i class="fa  fa-map-marker text-primary"></i>
            <span class="p-left-16-px">Calle Recoletos, 1 Bajo, 28001 Madrid, España</span>
          </li>
          <li class="p-10-px" style="display: flex;">
            <i class="fa fa-clock-o text-primary"></i>
            <div>
              <p class="p-left-16-px">Lunes a jueves de 8:00 a 15:00 horas, oficina abierta al público por trabajo presencial.</p>
              <p class="p-left-16-px">Viernes de 8:00 a 15:00 horas, oficina no abierta al público por trabajo a distancia.</p>
            </div>
          </li>
          <li class="p-10-px">
            <i class="fa fa-phone text-primary"></i>
            <span class="p-left-16-px">Teléfono: 91 360 16 78</span>
          </li>
          <li class="p-10-px">
            <span class="p-left-30-px">Fax: 91 429 03 17</span>
          </li>
          <li class="p-10-px">
            <i class="fa fa-envelope-o text-primary"></i>
            <a class="p-left-16-px text-decoration-none text-black" href="mailto:cermi@cermi.es">Correo electrónico: cermi@cermi.es</a>
          </li>
          <li class="p-10-px">
            <i class="fa fa-globe text-primary"></i>
            <a class="p-left-16-px text-decoration-none text-black" href="http://www.CERMI.es">http://www.CERMI.es/</a>
          </li>
        </ul>
      </article>
      <div class="grid m-1-px w-38">
        <div class="content-map">
          <mapOpenLayer></mapOpenLayer>
        </div>
      </div>
      <div class="grid w-20 m-1-px">
        <img class="w-15-rem h-23-rem" src="/img/contact/contact-3.png" alt="imagen contacto">
      </div>
    </section>
    <section id="como-llegar">

      <h2 class="title-grand container">Cómo llegar</h2>

      <div class="background-blue-3 ">
        <div class="container">
          <div>
            <h3 class="title font-size-30 text-center m-0-px" tabindex="-1">Cómo llegar</h3>
            <span class="underline m-left-52"></span>
          </div>

          <div class="grid-3 m-top-40-px m-bottom-40-px">
            <div class="grid background-white text-center shadow p-1-rem">
              <h4 class="font-family-frank-ruhl font-size-21 text-primary text-bold">En Metro</h4>
              <br />
              <br />
              <p class="text-bold">Línea 2 Metro (Retiro o Banco de España)</p>
              <p class="text-bold">Línea 4 Metro (Colón)</p>
            </div>
            <div class="grid background-white text-center shadow p-1-rem">
              <h4 class="font-family-frank-ruhl font-size-21 text-primary text-bold">En Bus</h4>
              <br />
              <br />
              <p class="text-bold">Castellana: 27, 14, 45, 150, 53, 37, 5</p>
              <p class="text-bold">Puerta de Alcalá: 1, 9, 51, 74, 19</p>
            </div>
            <div class="grid background-white text-center shadow p-1-rem">
              <h4 class="font-family-frank-ruhl font-size-21 text-primary text-bold">En Cercanías</h4>
              <br />
              <br />
              <p class="text-bold">Estación de Recoletos, Paseo de Recoletos</p>
              <p style="font-style: italic;">Estación accesible (ascensores) para personas con movilidad reducida</p>
            </div>
          </div>

          <div>
            <h3 class="subtitle font-size-21">Plazas de estacionamiento reservado para personas con movilidad reducida
              (PMR) más próximas a la sede del CERMI:</h3>
          </div>
          <ul class="p-x-1-rem">
            <li>
              <p class="text-bold">Calle Recoletos, 17. Una plaza de aparcamiento reservado PMR.</p>
            </li>
            <li>
              <p class="text-bold">Calle Villalar, 13. Tres plazas de aparcamiento reservado PMR. Laborables, de 7.30 a
                20.00 horas</p>
            </li>
            <li>
              <p class="text-bold">Calle Villanueva, 2. Dos plazas de aparcamiento reservado PMR. Laborables, de 9.00
                a 13.00 horas</p>
            </li>
          </ul>

          <article class="font-family-roboto font-size-16 font-light">
            Los datos de carácter personal que nos aporta se incorporan a un fichero responsabilidad de CERMI y serán
            utilizados para responder adecuadamente a la solicitud que nos haya formulado. Puede ejercer sus derechos de
            acceso, rectificación, cancelación y oposición a través del correo electrónico CERMI@CERMI.es.<br /><br />

            (*) significa que ese campo hay que rellenarlo obligatoriamente para enviar un mensaje.
          </article>
        </div>
      </div>
    </section>
    <br />
    <section class="p-3-rem font-family-roboto font-light" id="formulario">
      <div class="text-center">
        <h2 class="title-grand title__double-center text-center w-100" data-text="Puede contactar con nosotros a través de este formulario:">Formulario</h2>
      </div>

      <div class="form-contact ">
        <formContact></formContact>
      </div>
    </section>
    <br />
    <other-links-interest class="links-interest-box m-y-4-rem container" />


  </div>
</template>

<script>
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import formContact from "@/components/Global/formContact";
  import mapOpenLayer from "@/components/Global/map.vue";

  export default {
    name: "Contact",
    data: () => ({
      model: {
        name: ""
      }
    }),
    components: {
      OtherLinksInterest,
      HeroGlobal,
      formContact,
      mapOpenLayer
    },
    mounted() {
      if (this.$route.hash) {
        let element = this.$route.hash.replace('#', '')
        const offsetTop = document.getElementById(element).offsetTop - 150;
        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: offsetTop,
          });
        }, 200);
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../styles/form";

  .form-contact {
    max-width: 750px;
    margin: 0 auto;
  }

  #como-llegar {
    .background-blue-3 {
      padding: 60px 0;
    }

    .grid-2 {
      margin: 40px 0;
    }

    article {
      margin-top: 20px;
    }
  }
</style>