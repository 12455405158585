<template class="contact">
  <template v-if="formSend">
    <p class="msg-succes">Gracias por contactar con nosotros. <br>Pronto nos pondremos en contacto con usted.</p>
  </template>
  <template v-else>
    <Form ref="formObject" @submit="onSubmit">
      <div class="column">
        <label form="nombre">{{$t('fields.nombre')}}</label>
        <Field v-model="form.nombre" name="nombre" type="text" class="form-control keyword" rules="required" :placeholder="$t('placeholders.name')" />
        <ErrorMessage name="nombre" />
      </div>
      <div class="column">
        <label form="email">{{$t('fields.email')}}</label>
        <Field v-model="form.email" name="email" type="text" class="form-control email" rules="required|email" :placeholder="$t('placeholders.email')" />
        <ErrorMessage name="email" />
      </div>
      <div class="column">
        <label form="telefono">{{$t('fields.telefono')}}</label>
        <Field v-model="form.telefono" name="telefono" type="text" class="form-control phone" rules="required|digits:9" :placeholder="$t('placeholders.phone')" />
        <ErrorMessage name="telefono" />
      </div>
      <div class="column">
        <label form="asunto">{{$t('fields.asunto')}}</label>
        <Field v-model="form.asunto" name="asunto" type="text" class="form-control asunto" rules="required" :placeholder="$t('placeholders.subject')" />
        <ErrorMessage name="asunto" />
      </div>
      <div class="column">
        <label for="mensaje">{{$t('fields.mensaje')}}</label>
        <Field as="textarea" name="mensaje" class="input-styled form-control" :placeholder="$t('placeholders.message')" v-model="form.mensaje" rules="required" />
        <ErrorMessage name="mensaje" />
      </div>
      <div class="checkbox-styled">
        <Field id="aceptacion_legal" name="aceptacion_legal" type="checkbox" v-model="form.aceptacion_legal" :value="true" :unchecked-value="false" rules="required" />
        <label for="aceptacion_legal" v-html="privacyPolicy"> </label>
        <ErrorMessage name="aceptacion_legal" />
      </div>
      <p class="color-primary "><strong>En caso de que desee recibir información marque la siguiente casilla:</strong></p>

      <div class="checkbox-styled">
        <Field id="newsletter" name="newsletter" type="checkbox" v-model="form.newsletter" :value="true" :unchecked-value="false" />
        <label for="newsletter" v-html="$t('fields.newsletter')"> </label>
        <ErrorMessage name="newsletter" />
      </div>
      <div class="column-center w-100">
        <button class="btn-white" @click="recaptcha">{{$t('fields.enviar')}}</button>
      </div>
    </Form>
  </template>

</template>

<script>
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import { i18n } from '@/i18n'
  import router from '@/router'
  export default {
    name: '"form-contact',
    data() {
      let variables = {
        formSend: false,
        loading: false,
        form: {
          nombre: null,
          email: null,
          asunto: null,
          mensaje: null,
          telefono: null,
          aceptacion_legal: '',
          recaptcha: ''
        },

        result: null,
        i18n: i18n, //Only for the watcher
      }
      return variables;
    },
    components: {
      Form,
      Field,
      ErrorMessage
    },
    computed: {
      privacyPolicy: function () {
        return this.$t('fields.privacy_policy')
      }
    },
    methods: {
      onSubmit() {

        this.$recaptcha("contact").then((token) => {
          //console.log(token)
          this.form.recaptcha = token
          let t = this;
          let data = new FormData();
          for (var key in this.form) {
            data.append(key, this.form[key]);
          }
          t.loading = true
          let promise = this.axios.post('https://back.cermi.es/api/auto_contact/cermi-web', data);
          promise.then(function (result) { 
            t.result = JSON.stringify(result.data);
            if (result.data.status == true) {
              if (document.getElementById('formulario')) {
                var offsetTop = document.getElementById('formulario').offsetTop - 150;
                setTimeout(function () {
                  scroll({
                    behavior: "smooth",
                    top: offsetTop,
                  });
                }, 200);
              }
              t.loading = false
              t.formSend = true
              //console.log(document.getElementById('formulario'))

            }
          });
        });
      },
      handleFile(event, name) {
        this.form[name] = event.target.files[0];
      }
    },
    watch: {
      'i18n.global.locale'() {
        this.$refs.formObject.validate();
      },
      "loading"() {
        if (this.loading) {
          let loader = this.$loading.show({
            container: this.$refs.loadingContainer,
            zIndex: 99,
          });
          setTimeout(() => {
            loader.hide()
          }, 1000)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  label::before {
    background: none;
  }
</style>